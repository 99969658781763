import React from 'react';
import '../../components-style.css';

function Header(){
  return (
    <div className="header">
      
    </div>
  );
};

export default Header;